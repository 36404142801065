/*
    Created on :  out/2018
    Author     :  emers
*/
/*@font-face{
    font-family: Klavika-Bold;
    src:url(../fonts/Klavika-Bold.ttf);
}*/

.menu-content a{
  width: auto;
}

.menu-content .nav>li>a {
  display: inline-block;
}


/* === FONT CUSTOM === */
.ft-sz-75{
  font-size:75px;
}
.ft-sz-60 {
  font-size: 60px;
}
.ft-sz-50 {
  font-size: 60px;
}
.ft-sz-40 {
  font-size: 40px;
}
.ft-sz-30 {
  font-size: 30px;
}
.ft-sz-20 {
  font-size: 20px;
}
.ft-sz-15 {
  font-size: 15px;
}
.ft-sz-12 {
  font-size: 12px;
}
.ft-sz-10 {
  font-size: 10px;
}
.ft-sz-9 {
  font-size: 9px;
}

#lang ul li a:hover, #icons > span:hover, #menu .nav li a:hover,
#social a:hover, #icons a:hover {
  color: rgb(44, 173, 160);
}
#menu [class^="col-"], #menu .row {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  /*    max-width: 100vw;*/
}
#menu, .menu-header, .menu-content, .menu-footer{
  background-color: #ececec;
  left: 0;
  width: 100vw;
}
#menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9997;
  min-height: 100%;
  display: block;
}
#menu.closed {
  top: -110%;
}
#menu.opened {
  top: 0;
}
#menu ul {
  margin: 0;
}
.menu-header {
  position: fixed;
  top: 0;
  z-index: 9998;
  height: 80px;
}
.menu-content {
  min-height: 400px;
  height: auto;
  padding-top: 80px;
  /*padding-bottom: 120px;*/
  padding-bottom: 165px;
}
.menu-footer {
  position: absolute;
  bottom: 0;
  height: 130px;
  border-top: 1px dotted #000;
}

#wrapper.m-opened {
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;;
}
#menu.closed {
  left: -110%;
  top: 0;
}
#menu.opened {
  left: 0;
  top: 0;
}
#menu.cross .hamburguer span:nth-child(2){
  width: 0%;
}
#menu.cross .hamburguer span:nth-child(3){
  width: 80%;
  top: -52%;
  left: 0;
  transform: rotate(-225deg);
}
#menu.cross .hamburguer span:nth-child(1){
  width: 80%;
  transform: rotate(225deg);
  top: 25%;
  left: 0;
}
#menu.cross .hamburguer span:nth-child(1), #menu.cross .hamburguer span:nth-child(3) {
  position: absolute;
}

/* === menu header === */
.menu-header > .row, .menu-header > .row > div {
  height: 100%;
}
.menu-header > .row > div {
  position: relative;
}
.hamburguer{
  position: absolute;
  z-index: 9999;
  width: 35px;
  height: auto;
  top: 50%;
  left:50%;
  transform: translate3d(-50%, -70%, 0);
  cursor: pointer;
}
.hamburguer span{
  position: relative;
  display: block;
  width: 66%;
  height: 3px;
  background-color: #000;
  transition: all 0.2s ease-in-out;
}
.hamburguer span:first-of-type{
  width: 100%;
  margin-bottom: 3px;
}
.hamburguer span:last-of-type{
  margin-top: 3px;
}
.hamburguer:hover span{
  width: 100%;
  background-color: rgb(44, 173, 160);
}
#logo{
  position: relative;
  left: 0;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  padding-left: 10px;
}
#logo img{
  height: 30px;
  width: auto;
}

#social {
  position: relative;
  display: inline-block;
  float: right;
  right: 3%;
}
#social > a {
  height: 100%;
  width: 35px;
  float: left;
  text-align: center;
  display: inline-block;
  line-height: 50px;
}
#social > a:last-of-type {
  float: right;
}
#social #linked-in {
  /*position:relative;
  padding-right: 0;*/
}
#social.open{
  display:none;
}

/* === menu content === */

.menu-content > .row {
  border-top: 1px dotted #000;
}
.menu-items {
  display: block;
  overflow: hidden;
}
.menu-items ul.nav{
  padding: 0;
  overflow: hidden;
}
.menu-items ul.nav li{
  font-family: Klavika-Bold;
  position: relative;
  left: 0;
  list-style: none;
  padding: 0;
  width: 100%;
  text-align: center;
}
.menu-items ul.nav li:first-child{
  /*    margin-top: 80px;*/
}
.menu-items ul.nav li:hover{

}
.menu-icones{
  position: relative;
  text-align: center;
}
#icons{
  display: inline-block;
  text-align: center;
}
#icons span {
  font-size: 13px;
  font-family: Klavika-Bold;

  display: inline-grid;
  width: 19%;
  max-width: 190px;
  text-align: center;
}
#icons span img{
  width: 60%;
  margin: 10px auto 0 auto;
}


/* === menu footer === */

.menu-footer .row {
  margin: 0;
  padding: 0;
  padding-top: 20px;
}

#lang {
  height: 100%;
  display: block;
}
#lang ul {
  margin: 0!important;
  padding: 0!important;
  width: 100%;
  font-family: Roboto;
  font-size: 18px;
  text-transform: uppercase;
}
#lang ul li {
  list-style: none;
  margin: 10px auto;
  text-align: center;
  width: 100%;
}
#lang ul li a {
  display: block;
  height: 100%;
  width: auto;
}

/* === FONT CUSTOM === */
.ft-sz-75{
  font-size:75px;
}
.ft-sz-60 {
  font-size: 60px;
}
.ft-sz-50 {
  font-size: 60px;
}
.ft-sz-40 {
  font-size: 40px;
}
.ft-sz-30 {
  font-size: 30px;
}
.ft-sz-20 {
  font-size: 20px;
}
.ft-sz-12 {
  font-size: 12px;
}
.ft-sz-15 {
  font-size: 15px;
}
.ft-sz-10 {
  font-size: 10px;
}
.ft-sz-9 {
  font-size: 9px;
}

@media screen and (min-width: 320px) {
  #menu [class^="col-"], #menu .row {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    /*max-width: 25%;*/
  }
  #social > a {
    width: 25px;
    float: right;
  }
  .ft-sz-75{
    font-size:45px;
  }
  .ft-sz-60 {
    font-size: 36px;
  }
  .ft-sz-50 {
    font-size: 30px;
  }
  .ft-sz-40 {
    font-size: 24px;
  }
  .ft-sz-30 {
    font-size: 18px;
  }
  .ft-sz-20 {
    font-size: 16px;
  }
  .ft-sz-15 {
    font-size:13px;
  }
  .ft-sz-12 {
    font-size: 10px;
  }
  .ft-sz-10 {
    font-size: 6px;
  }
  .ft-sz-9 {
    font-size: 5px;
  }
  .menu-items .ft-sz-12{
    font-size:18px;
  }
  #icons .ft-sz-10 {
    font-size: 11px;
    line-height: 1.0;
  }
  .menu-items ul.nav li{
    margin-top: 5px;
  }
  .menu-items ul.nav li:first-of-type {
    margin-top: 10px;
  }
  .menu-footer ul, .menu-content {
    line-height: 1.4;
  }
  .menu-footer .row {
    padding-top: 8px;
  }
}



@media screen and (min-width: 768px) {
  #menu [class^="col-"], #menu .row {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    max-width: 100vw;
  }

  /* === FONTS === */
  .ft-sz-75{
    font-size:60px;
  }
  .ft-sz-60 {
    font-size: 48px;
  }
  .ft-sz-50 {
    font-size: 40px;
  }
  .ft-sz-40 {
    font-size: 32px;
  }
  .ft-sz-30 {
    font-size: 24px;
  }
  .ft-sz-20 {
    font-size: 16px;
  }
  .ft-sz-15 {
    font-size: 12px;
  }
  .ft-sz-12 {
    font-size: 10px;
  }
  .ft-sz-10 {
    font-size: 8px;
  }
  .ft-sz-9 {
    font-size: 7px;
  }
  /*    #social {
          padding-right: 30px !important;
          right: 3%;
      }*/
  .menu-items, #lang{
    padding-left: 8.33% !important;
  }
  .menu-items ul.nav li, #icons, #lang ul li {
    text-align: left;
  }
  #icons{
    padding-left: 12.66% !important;
  }
  .menu-items .ft-sz-12 {
    font-size: 36px;
  }
  #menu ul{
    line-height: 1.5;
  }
  #lang .ft-sz-15{
    font-size: 18px;
  }
  #icons span {
    width: 30%;
  }
  #icons .ft-sz-10 {
    font-size: 20px;
    line-height: 1.2;
  }
  .row .title-bg .ft-sz-12{
    font-size: 20px !important;
  }
  .row .title-bg .ft-sz-30{
    font-size: 50px !important;
    height: 137px !important;
  }
  .tpl-page-content .content .ft-sz-15{
    font-size: 20px !important;
  }
  .tpl-page-content .content table img {
    width: 100px !important;
    margin: 10px 10px 10px 0px !important;
  }
}

@media screen and (min-width: 992px) {
  /* === FONTS === */
  .ft-sz-75{
    font-size:75px;
  }
  .ft-sz-60 {
    font-size: 60px;
  }
  .ft-sz-50 {
    font-size: 50px;
  }
  .ft-sz-40 {
    font-size: 40px;
  }
  .ft-sz-30 {
    font-size: 30px;
  }
  .ft-sz-20 {
    font-size: 20px;
  }
  .ft-sz-15 {
    font-size: 15px;
  }
  .ft-sz-12 {
    font-size: 12px;
  }
  .ft-sz-10 {
    font-size: 10px;
  }
  .ft-sz-9 {
    font-size: 9px;
  }
  #menu {
    top: -110%;
    left: 0;
  }
  #menu.closed {
    top: -400%;
    left: 0;
  }
  #menu.opened {
    top: 0;
    left: 0;
  }
  .menu-header {
    height: 80px;
  }
  #logo img{
    height: 50px;
  }
  .menu-content {
    padding-top: 80px;
  }
  .hamburguer {
    width: 50px;
    top: 50%;
    left: 50%;
  }
  .hamburguer span {
    height: 5px;
  }
  .hamburguer span:first-of-type {
    margin-bottom: 5px;
  }
  .hamburguer span:last-of-type {
    margin-top: 5px;
  }
  #social > a {
    height: 100%;
    width: 27px;
    margin-top: 0;
    padding-top: 0;
    line-height: 80px;
  }
  .menu-items, #lang{
    padding-left: 8.33% !important;
  }
  .menu-items ul.nav{
    padding-top: 30px;
  }
  #lang ul li, .menu-items ul.nav li {
    text-align: left;
  }
  #icons{
    padding-left: 0 !important;
  }
  .menu-icones{
    text-align: left;
  }
  #icons {
    position: absolute;
    bottom: 0;
  }
  .menu-icones{
    padding-top: 5px;
  }
}

@media screen and (min-width: 1200px) {
  /* === FONTS === */
  .ft-sz-75{
    /*font-size:75px;*/
  }
  .ft-sz-60 {
    font-size: 90px;
  }
  .ft-sz-50 {
    font-size: 75px;
  }
  .ft-sz-40 {
    /*font-size: 68px;*/
  }
  .ft-sz-30 {
    font-size: 45px;
  }
  .ft-sz-20 {
    font-size: 30px;
  }
  .ft-sz-15 {
    font-size: 23px;
  }
  .ft-sz-12 {
    font-size: 18px;
  }
  .ft-sz-10 {
    /*font-size: 10px;*/
  }
  .ft-sz-9 {
    font-size: 14px;
  }
}

@media screen and (min-width: 1600px) {
  /* === FONTS === */
  .ft-sz-75{
    /*font-size:75px;*/
  }
  .ft-sz-60 {
    font-size: 120px;
  }
  .ft-sz-50 {
    font-size: 100px;
  }
  .ft-sz-40 {
    /*font-size: 68px;*/
  }
  .ft-sz-30 {
    font-size: 60px;
  }
  .ft-sz-20 {
    font-size: 40px;
  }
  .ft-sz-15 {
    font-size: 30px;
  }
  .ft-sz-12 {
    font-size: 24px;
  }
  .ft-sz-10 {
    /*font-size: 10px;*/
  }
  .ft-sz-9 {
    font-size: 18px;
  }
  #icons .ft-sz-10{
    font-size:19px;
  }
  #lang .ft-sz-15 {
    font-size: 18px;
  }
}










/*#lang{
    background-color: #ececec;
}*/
/*.lang-col{
    position: fixed;
    bottom:0;
    width:100%;
}*/


/*


#app-main-menu {
    background: #ededed;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    padding: 0;
    border-bottom: 1px solid #ccc;
    height: 80px;
    width: 100%;
}
.hamburguer {
    position: absolute;
    z-index: 1001;
    width: 50px;
    height: 25px;
        background-color: #A0A0A0;
    top: 50%;
    transform: translate3d(0,-50%,0);
    cursor: pointer;
    left: 20px;
}
.hamburguer:hover > span {
    background-color: rgb(44, 173, 160);
}
.hamburguer span{
    position: relative;
    display: block;
    width: 66%;
    height: 5px;
    background-color: #000;
    transition: all 0.2s ease-in-out;
}
.hamburguer span:first-of-type{
    width: 100%;
    margin-bottom: 5px;
}
.hamburguer span:last-of-type{
    margin-top: 5px;
}
.hamburguer:hover span{
    width: 100%;
}

#app-main-menu.open .hamburguer span:nth-child(2){
    width: 0%;
}
#app-main-menu.open .hamburguer span:nth-child(3){
    width: 80%;
    top: -52%;
    left: 0;
    transform: rotate(-225deg);
}
#app-main-menu.open .hamburguer span:nth-child(1){
    width: 80%;
    transform: rotate(225deg);
    top: 25%;
    left: 0;
}
#logo{
    position: absolute;
    height: 50px;
    width: 100%;
    text-align: left;
    left: 5%;
    top: 15px;
}
#logo img{
    max-width: 100%;
    max-height: 100%;
}
#app-main-menu.open #logo{
    display:none;
}


 === MENU ===

#menu{
    background: #ececec;
    display: block;
    position: absolute;
    left: 0;
    top: -100vh;
    height: 100vh;
    z-index: 9998;
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
}

#menu.open{
    position:fixed;
    overflow-x: hidden;
}




#icons{
    position: relative;
    display: inline-block;
    top: -120px;
    float: right;
    width:50%;
    cursor: pointer;

}
#icons span{
    font-family: Klavika-Bold;
    display: inline-grid;
    margin-right: 25px;
    font-size: 18px;
    width: 19%;
    text-align:center;
}
#icons span img{
    margin:0 auto;
    max-height:  50%;
    max-width:  50%;

}

#lang ul li a:hover, #icons > span:hover, #menu .nav li a:hover,
#social a:hover {
    color: rgb(44, 173, 160);
}


 MEDIA QUERIES

@media screen and (min-width: 320px) {
    #menu ul.nav li:first-child{
        margin-top:0px;
    }
    #menu ul.nav li{
        left:0  !important;
        text-align: center;
        margin-top:20px;
        width:100%;
        font-size: 21px;
    }
    .row{
        padding-top:10px;
        margin-top:50px;
    }
    #icons{
        width:100%;
        top:0;
        text-align: center;
        width:100%;
    }
    #icons span{
        font-size: 15px;
    }
    #lang{
        position:fixed;
        bottom:0;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    #lang ul{
        width:100%;
        left:0%;
        text-align: center;
        font-size:16px;
    }
    #lang ul li{
        margin: 8px auto;
    }
    #logo{
        height: 27px;
        top: 15px;
        left: 20%;
    }

}

@media screen and (min-width: 768px) {
    #menu ul.nav li {
        left: 0 !important;
        text-align: center;
        margin-top: 20px;
        width: 100%;
        font-size: 21px;
    }
    #logo {
        height: 27px;
        top: 15px;
        left: 10%;
    }
    .row{
        padding-top: 30px;
    }
    #menu ul.nav li{
        text-align:  left!important;
        left:10% !important;
    }

    #icons{
        top:-80px;
        text-align:right;
        width:55%;
        right:10%
    }

    #lang ul li{
        text-align:  left!important;
        margin:10px auto;
    }

    #menu ul.nav li{
        font-size:26px;
    }

    #lang ul{
        left:10%;
        font-size:12px;
    }

    #icons span{
        width:15%;
        font-size:12px;
    }
}

@media screen and (min-width: 992px) {
    #logo {
        height: 27px;
        top: 15px;
        left: 8%;
    }
    #menu ul.nav li{
        font-size: 27px;
    }

    #icons span {
        width: 14%;
        font-size: 14px;
    }

    #icons {
        top: -95px;
    }

    #lang ul{
        font-size:12px;
    }

}

 ONLY MOBILE
@media screen and (max-width: 1200px) {

    #social{
        width:70px;
        right: 1%;
    }
    #app-main-menu{
        height:50px;
    }

    .hamburguer{
        height:18px;
        width: 35px;
    }
    .hamburguer span{
        height:3px;
    }
}

@media screen and (min-width: 1200px) {
        #logo {
            height: 35px;
            top: 25px;
            left: 8%;
        }


    #menu ul.nav li{
        font-size:28px;
    }

    #icons {
        top: -95px;
        text-align: right;
        width: 45%;
        right: 10%;
    }

    #lang ul {
        font-size: 14px;
    }
}

@media screen and (min-width: 1600px) {
        #logo {
            height: 35px;
            top: 25px;
            left: 8%;
        }

    #menu ul.nav li {
        font-size: 30px;
    }

    #icons {
        top: -130px;
        text-align: right;
        width: 60%;
        right: 10%;
    }

    #lang ul {
        font-size: 16px;
    }

    #icons span {
        width: 8%;
        font-size: 20px;
    }
}*/
